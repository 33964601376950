import React, { Fragment } from "react";
import { ThemeProvider } from "styled-components";
import { interiorTheme } from "common/theme/interior";
import Layout from "../containers/Home/Layout";
import Titlebar from "common/components/Titlebar";
import "common/assets/css/main-page.css";

import Seo from "components/seo";


const Contact = () => {
  const seoData = {
    title: "Contact Radiance | Get in Touch for Expert Hair Solutions",
    description: "Get in touch with Radiance Hair Studio for personalized hair solutions, including premium wigs, hair extensions, and expert consultations.",
    keywords: ["Contact Us"],
  };

  const schemaData = {
    "@context": "https://schema.org",
    "@type": "ContactPage",
    "@id": "https://www.radiancehairstudio.com/contact.php",
    "alternateName": "Radiance Hair Studio",
    "additionalType": "https://www.radiancehairstudio.com/contact.php",
    "description": "Get in touch with Radiance Hair Studio for personalized hair solutions, including premium wigs, hair extensions, and expert consultations.",
    "image": "https://www.radiancehairstudio.com/home/home-image-18.webp",
    "keywords": "Contact Us, Radiance Hair Studio contact, hair solutions contact"
  };  


  return (
    <ThemeProvider theme={interiorTheme}>
      <Fragment>
      <Seo {...seoData} schemaData={schemaData} />
        <Layout>
          <Titlebar title="Contact Us" />
        </Layout>
      </Fragment>
    </ThemeProvider>
  );
};
export default Contact;